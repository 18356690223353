<template>
  <div class="kefu-page">
    <div class="kefu-top">客服列表</div>
    <div class="kefu-list">
      <div class="list flex-center" v-for="(item, i) in kefuList" :key="i">
        <img :src="item.Avatar ? `/upload/${item.Avatar}` : kefuUrl1" alt="">
        <div class="flex-1">
          <div class="label">{{item.Name}}</div>
          <div class="desc">{{item.Type}}</div>
        </div>
        <Button class="btn" @click="to(item.Type, item.Link)">联系</Button>
      </div>
    </div>
  </div>
</template>
<script>
import { Button } from 'vant'
import { getKefuList } from '@/api/user'
import "@/utils/sdk.js"
export default {
  components: {
    Button
  },
  data() {
    return {
      kefuList: [],
      kefuUrl1: require('../../assets/imgs/kefu-1.png'),
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      getKefuList().then(res => {
        this.kefuList = res.data.Items
        this.kefuList.map(v =>{
          if(v.Type == "chatwoot") {
            this.chatwoot(v.Link)
          }
        })
      })
    },
    to(Type,Link){
      let tmp = undefined
      let that = this
      if(Type == "chatwoot") {
        if(window.$chatwoot === undefined) {
          tmp = setInterval(function (){
            // that.chatwoot()
            if (window.$chatwoot !== undefined) {
              clearInterval(tmp)
              window.$chatwoot.toggle('open');
            }
          },50)
        } else {
          window.$chatwoot.toggle('open');
        }
      } else {
        switch (Type) {
          case "wechat":
            Link = "weixin://" + Link
            break;
          case "qq":
            Link = "mqq://"+ Link
            break;
          case "whatsapp":
            Link = "whatsapp://send?phone="+ Link
            break;
          case "telegram":
            Link = "tg://resolve?domain="+ Link
            break;
          case "facebook":
            Link = "fb://"+ Link
            break;
          case "twitter":
            Link = "twitter://"+ Link
            break;
          case "instagram":
            Link = "instagram://"+ Link
            break;
            case "line":
            Link = "https://line.me/R/"+ Link
            break;
        }
        this.toPath(Link)
      }
    },
    toPath(path) {
      let a = document.createElement('a')
      a.href = path
      a.target = '_blank'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    chatwoot(Link){
      let that = this;
      if (Link.length === 0) {
        return;
      }
      let len = Link.indexOf("/widget");
      if (len === -1) {
        return;
      }
      var BASE_URL=Link.substr(0,len);
      let tokenlen = Link.indexOf("token=");
      let token = Link.substr(tokenlen+6);
      window.chatwootSDK.run({
        websiteToken: token,
        baseUrl: BASE_URL
      });
      that.inter = setInterval(function(){
        if(document.getElementsByClassName("woot-widget-bubble")[0] !== undefined) {
          that.show = true;
          clearInterval(that.inter);
          window.$chatwoot.toggleBubbleVisibility('hide');
        }
      },50);
    },
  }
}
</script>